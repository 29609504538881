import './style.scss';

import { Button, Divider, Modal } from 'antd';
import {
  Classes,
  ListClassesCreatePaQueryVariables,
  useListClassesCreatePaLazyQuery,
} from '@services/data-access';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { DialogProps } from '@models/dialog';
import SelectClassPopUpFilter from './filter/SelectClassPopUpFilter';
import SelectClassPopUpTable from './table/SelectClassPopUpTable';
import { StatusCode } from '@models';
import { ProgramCategory } from '@models/program';
import moment from 'moment';

export interface SelectClassPopUpProps extends DialogProps {
  previousSelectedClasses: Classes[];
  companyId: string;
}

const DEFAULT_PAGE_SIZE = 5;
const DEFAULT_PAGE = 1;

const SelectClassPopUp: FunctionComponent<SelectClassPopUpProps> = ({
  onClose,
  visible,
  companyId,
  previousSelectedClasses,
}) => {
  const selectedClassed = useRef<Classes[]>([]);

  const [filterState, setFilterState] = useState({
    selectedYear: moment().format('YYYY'),
    selectedCurriculum: 'root',
    selectedLevel: 'root',
    selectedSubject: 'root',
    programmeCategories: 'root',
    programType: 'root',
    branch: 'root',
    currentPage: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [
    getClassList,
    {
      data: classList,
      loading: loadingClassList,
      called: calledClassList,
      fetchMore: fetchMoreClassList,
    },
  ] = useListClassesCreatePaLazyQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onError: () => {},
  });

  useEffect(() => {
    if (!!companyId) {
      let finalFilter: any = {
        ...(companyId && { companyId: { _eq: companyId } }),
        status: { _neq: StatusCode.Deleted },
        classStatus: { _neq: 'closed' },
        year: { _eq: filterState.selectedYear },
      };
      let filterLessons: any = {
        lessonStatus: { _neq: 'cancel' },
      };
      let filterProgram = [];
      let filterProgramTypes: any[] = [];

      if (
        filterState.programType &&
        filterState.selectedCurriculum &&
        filterState.selectedLevel &&
        filterState.selectedSubject &&
        filterState.branch
      ) {
        if (filterState.branch && filterState.branch !== 'root') {
          filterLessons.classroom = {
            branchId: {
              _eq: filterState.branch,
            },
          };
        }
        if (
          filterState.selectedCurriculum &&
          filterState.selectedCurriculum !== 'root'
        ) {
          filterProgram.push({
            curriculumIds: {
              _contains: filterState.selectedCurriculum,
            },
          });
        }
        if (filterState.selectedLevel && filterState.selectedLevel !== 'root') {
          filterProgram.push({
            levelIds: { _contains: filterState.selectedLevel },
          });
        }
        if (
          filterState.selectedSubject &&
          filterState.selectedSubject !== 'root'
        ) {
          filterProgram.push({
            subjectId: { _eq: filterState.selectedSubject },
          });
        }
        filterProgram.push({
          cate: {
            code: {
              ...(filterState.programmeCategories === 'root'
                ? {
                    _neq: ProgramCategory.DoubleLesson,
                  }
                : { _eq: filterState.programmeCategories }),
            },
          },
        });

        if (filterState.programType && filterState.programType !== 'root') {
          filterProgramTypes.push({
            programTypeId: {
              _eq: filterState.programType,
            },
          });
        }

        finalFilter.lessons = filterLessons;

        if (filterProgram.length > 0) {
          finalFilter.program = {
            _and: filterProgram,
          };
        }

        if (filterProgramTypes.length > 0) {
          finalFilter._and = filterProgramTypes;
        }
      }
      const variables: ListClassesCreatePaQueryVariables = {
        offset: (filterState.currentPage - 1) * filterState.pageSize,
        limit: filterState.pageSize,
        filter: finalFilter,
      };
      if (calledClassList) {
        fetchMoreClassList({
          variables,
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          },
        });
      } else {
        getClassList({ variables });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyId,
    calledClassList,
    fetchMoreClassList,
    getClassList,
    filterState,
  ]);

  const onFilter = (value: any) => {
    setFilterState({
      ...filterState,
      selectedYear: value.year,
      selectedCurriculum: value.curriculum,
      selectedLevel: value.level,
      selectedSubject: value.subject,
      programmeCategories: value.programmeCategories,
      programType: value.programmeType,
      branch: value.branch,
      currentPage: DEFAULT_PAGE,
    });
  };

  const handleOnChangePageSize = (value: number) => {
    setFilterState({
      ...filterState,
      currentPage: DEFAULT_PAGE,
      pageSize: value,
    });
  };

  const handleOnChangePage = (value: number) => {
    setFilterState({
      ...filterState,
      currentPage: value,
    });
  };

  const onSelectedClassedChanged = (classes: Classes[]) => {
    selectedClassed.current = classes;
  };

  return (
    // @ts-ignore
    <Modal
      title={`Select a Programme & Class`}
      visible={visible}
      onCancel={() => onClose('cancel')}
      width="100%"
      className="class-list-popup"
      forceRender
      footer={[
        <Button
          className="fixed-width-btn button-black-text"
          key="back"
          onClick={() => onClose('cancel')}
        >
          Close
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            onClose('success', selectedClassed.current);
          }}
        >
          Add to Payment
        </Button>,
      ]}
    >
      <SelectClassPopUpFilter onFilter={onFilter} companyId={companyId} />
      <Divider className="indigo-divider-header" />
      <SelectClassPopUpTable
        previousSelectedClasses={previousSelectedClasses.slice() || []}
        onSortChange={() => {}}
        onItemsPageChange={handleOnChangePageSize}
        onPageChange={handleOnChangePage}
        currentPage={filterState.currentPage}
        total={classList?.classes_aggregate.aggregate?.count || 0}
        pageSize={filterState.pageSize}
        isLoading={loadingClassList}
        data={(classList?.classes as Classes[]) || []}
        disable={loadingClassList}
        onSelectedChange={onSelectedClassedChanged}
      />
    </Modal>
  );
};

export default SelectClassPopUp;
