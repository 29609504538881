import React from 'react';

import { Roles } from '@models';

// const MenuList = React.lazy(() => import('./menu/list'));
// const MenuForm = React.lazy(() => import('./menu/view/MenuForm'));
const CategoryList = React.lazy(() => import('./category/list'));
const CategoryForm = React.lazy(() => import('./category/view/CategoryForm'));
const RsCategoryList = React.lazy(() => import('./resource-category/list'));
const RsCategoryForm = React.lazy(
  () => import('./resource-category/view/ResourceCategoryForm'),
);
const PostList = React.lazy(() => import('./post/list'));
const PostForm = React.lazy(() => import('./post/view/PostForm'));
const RsDetailList = React.lazy(() => import('./resource-details/list'));
const RsDetailForm = React.lazy(
  () => import('./resource-details/view/CategoryDetailForm'),
);
const SurveyList = React.lazy(() => import('./survey/list'));
const SurveyForm = React.lazy(() => import('./survey/view/SurveyForm'));

export default [
  // {
  //   path: '/content-management/menu',
  //   component: MenuList,
  //   exact: true,
  //   title: 'Menu',
  //   isPublic: true,
  //   roles: [],
  // },
  // {
  //   path: '/content-management/menu/create',
  //   component: MenuForm,
  //   exact: true,
  //   title: 'Create Menu',
  //   isPublic: true,
  //   roles: [],
  // },
  // {
  //   path: '/content-management/menu/:menuId/update',
  //   component: MenuForm,
  //   exact: true,
  //   title: 'Edit Menu',
  //   isPublic: true,
  //   roles: [],
  // },
  {
    path: '/content-management/category',
    component: CategoryList,
    exact: true,
    title: 'Category',
    isPublic: true,
    roles: [Roles.Intern],
  },
  {
    path: '/content-management/category/create',
    component: CategoryForm,
    exact: true,
    title: 'Create Category',
    isPublic: true,
    roles: [Roles.Intern],
  },
  {
    path: '/content-management/category/:categoryId/update',
    component: CategoryForm,
    exact: true,
    title: 'Edit Category',
    isPublic: true,
    roles: [Roles.Intern],
  },
  {
    path: '/content-management/resource/category',
    component: RsCategoryList,
    exact: true,
    title: 'Resources Category',
    isPublic: true,
    roles: [Roles.Intern],
  },
  {
    path: '/content-management/resource/category/create',
    component: RsCategoryForm,
    exact: true,
    title: 'Create Resources Category',
    isPublic: true,
    roles: [Roles.Intern],
  },
  {
    path: '/content-management/resource/category/:rsCategoryId/update',
    component: RsCategoryForm,
    exact: true,
    title: 'Edit Resources Category',
    isPublic: true,
    roles: [Roles.Intern],
  },
  {
    path: '/content-management/post',
    component: PostList,
    exact: true,
    title: 'Post',
    isPublic: true,
    roles: [Roles.Intern],
  },
  {
    path: '/content-management/post/create',
    component: PostForm,
    exact: true,
    title: 'Create Post',
    isPublic: true,
    roles: [Roles.Intern],
  },
  {
    path: '/content-management/post/:postId/update',
    component: PostForm,
    exact: true,
    title: 'Edit Post',
    isPublic: true,
    roles: [Roles.Intern],
  },
  {
    path: '/content-management/resource/detail',
    component: RsDetailList,
    exact: true,
    title: 'Resources Details',
    isPublic: true,
    roles: [Roles.Intern],
  },
  {
    path: '/content-management/resource/detail/create',
    component: RsDetailForm,
    exact: true,
    title: 'Create Resources Details',
    isPublic: true,
    roles: [Roles.Intern],
  },
  {
    path: '/content-management/resource/detail/:rsId/update',
    component: RsDetailForm,
    exact: true,
    title: 'Resources Details',
    isPublic: true,
    roles: [Roles.Intern],
  },
  {
    path: '/content-management/survey',
    component: SurveyList,
    exact: true,
    title: 'Survey',
    isPublic: true,
    roles: [Roles.Intern],
  },
  {
    path: '/content-management/survey/create',
    component: SurveyForm,
    exact: true,
    title: 'Create Survey',
    isPublic: true,
    roles: [Roles.Intern],
  },
  {
    path: '/content-management/survey/:surveyId/update',
    component: SurveyForm,
    exact: true,
    title: 'Edit Survey',
    isPublic: true,
    roles: [Roles.Intern],
  },
];
