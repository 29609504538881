import moment, { Moment } from 'moment';

export type ClassStatus = 'open' | 'new' | 'closed';
export type WaitingStatus =
  | 'Waiting'
  | 'Expired'
  | 'Available'
  | 'Filling Fast'
  | 'Full Class'
  | 'Closed'
  | 'Unknown';

const getNewClassWaitingType = (waitingTime: Moment): WaitingStatus => {
  if (moment(new Date()).isBefore(waitingTime)) {
    return 'Waiting';
  } else {
    return 'Expired';
  }
};

const getOpenClassWaitingType = (
  lessonStudent: number,
  classCapacity: number,
): WaitingStatus => {
  const fillingRate = (lessonStudent / classCapacity) * 100;
  if (fillingRate < 80) {
    return 'Available';
  }
  if (fillingRate < 100) {
    return 'Filling Fast';
  }

  return 'Full Class';
};

export const getWaitingType = (
  waitingTime: Moment,
  classStatus: ClassStatus,
  lessonStudent: number,
  classCapacity: number,
): WaitingStatus => {
  switch (classStatus) {
    case 'closed':
      return 'Closed';
    case 'new':
      return getNewClassWaitingType(waitingTime);
    case 'open':
      return getOpenClassWaitingType(lessonStudent, classCapacity);
    default:
      return 'Unknown';
  }
};

export const uniqueCurriculum = (curriculums: any[]) => {
  const allowedAbbreviations = ['A', 'IP', 'EXP', 'IB'];

  const uniqueItemsMap: Map<string, any> = new Map();

  curriculums.forEach(item => {
    // Check if the item should be included based on its abbreviation
    let shouldInclude = false;

    if (item.abbreviation === 'A') {
      shouldInclude = item.curriculums_levels?.some((level: any) =>
        ['J1', 'J2'].includes(level.level.abbreviation),
      );
    } else if (['IP', 'EXP', 'IB'].includes(item.abbreviation)) {
      const requiredAbbreviations = ['S1', 'S2', 'S3', 'S4'];
      shouldInclude = item.curriculums_levels?.some((level: any) =>
        requiredAbbreviations.includes(level.level.abbreviation),
      );
    }

    // If shouldInclude is true and the abbreviation is in allowedAbbreviations
    if (shouldInclude && allowedAbbreviations.includes(item.abbreviation)) {
      // Use the abbreviation as a key in the Map to ensure uniqueness
      uniqueItemsMap.set(item.abbreviation, item);
    }
  });

  // Convert the Map values back to an array
  const filteredData = Array.from(uniqueItemsMap.values());

  // Sort the array based on the specified order
  const sortedData = filteredData.sort((a, b) => {
    const order =
      allowedAbbreviations.indexOf(a.abbreviation) -
      allowedAbbreviations.indexOf(b.abbreviation);
    return order;
  });

  return sortedData;
};

export const uniqueLevel = (
  curriculumsUnique: any[],
  curriculumId: string,
  allLevels: any[],
) => {
  const curriculumName = curriculumsUnique.find(
    (cur: any) => cur.id === curriculumId,
  )?.abbreviation;

  switch (curriculumName) {
    case 'A':
      return allLevels
        .filter(level => ['J1', 'J2'].includes(level.abbreviation))
        .sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
    case 'IP':
    case 'EXP':
      return allLevels
        .filter(level => ['S1', 'S2', 'S3', 'S4'].includes(level.abbreviation))
        .sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
    default:
      return allLevels.sort((a, b) =>
        a.abbreviation.localeCompare(b.abbreviation),
      );
  }
};
