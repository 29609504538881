import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Avatar, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiService } from '@services/api';
import { upload } from '@helpers/upload';

import styles from './index.module.scss';

const MAX_SIZE = 5 * Math.pow(10, 6);

export interface IndigoAvatar {
  size?: number;
  imgUrl: any;
  editable?: boolean;
  onChange?: (imgUrl: string) => void;
  icon?: any;
}

export const IndigoAvatar: FunctionComponent<IndigoAvatar> = ({
  size = 120,
  imgUrl,
  editable,
  onChange,
  icon,
}) => {
  const randomId = Math.random();
  const [logo, setLogo] = useState<any>('');
  const imageRef = useRef<HTMLInputElement | null>(null);

  const handleChange = async (selectorFiles: FileList) => {
    const file = selectorFiles[0];
    const fileExtension = file?.name?.split('.')?.pop();
    const fileSize = file?.size;

    if (file && fileExtension) {
      if (fileSize > MAX_SIZE) {
        message.warning('The image size exceeds the allowable limit.');
        if (imageRef?.current) {
          imageRef.current.value = '';
        }
        return;
      }

      const response = await apiService.get(
        `/media/put-presigned-url?key=${file?.name}`,
      );

      await fetch(response.data.url, {
        method: 'PUT',
        body: file,
      });

      if (onChange) {
        onChange(response.data.keyPath);
      }
    }
  };

  const _getImage = async (url: string) => {
    setLogo(upload.getFileUrl(url, 'image'));
  };

  useEffect(() => {
    // if (imgUrl) {
    _getImage(imgUrl);
    // }
  }, [imgUrl]);

  return (
    <div className={styles.wrapper}>
      {editable ? (
        <div className={styles.edit}>
          <input
            className={styles.editInput}
            id={`avatar-edit-${randomId}`}
            type="file"
            accept="image/gif, image/jpeg, image/png"
            onChange={(e: any) => handleChange(e.target.files)}
            ref={imageRef}
          />
          <label
            className={styles.editInputLabel}
            htmlFor={`avatar-edit-${randomId}`}
          >
            <FontAwesomeIcon icon={['far', 'image']} />
          </label>
        </div>
      ) : null}

      <div className={styles.preview} style={{ width: size, height: size }}>
        <Avatar
          size={size}
          src={logo}
          icon={icon || <FontAwesomeIcon icon={['far', 'user']} />}
        />
      </div>
    </div>
  );
};
